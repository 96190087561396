import React, { Component } from 'react';
import {Link, withRouter} from "react-router-dom";
import "../assets/content.scss";
import Card from "../../card";
import {get} from "../../../lib/api";
import {withApplicationState} from "react-application-state";
import moment from "moment";
import {Button} from "antd-mobile";
import applicationConfig from "../../../config/application";

class Content extends Component {

    constructor(props){
        super(props);
        this.state = {
            title: 'loading',
            loading: true,
            content: 'De pagina wordt geladen',
            slug: props.match.params && props.match.params.slug ? props.match.params.slug : '',
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const oldSlug = this.state.slug;
        const newSlug = nextProps.match.params && nextProps.match.params.slug ? nextProps.match.params.slug : '';

        if(oldSlug !== newSlug){
            this.setState({
                slug: newSlug
            }, () => {
                this.loadPage();
            })
        }
    }

    componentDidMount = async () => {
        await this.loadPage();
    }

    loadPage = async () => {
        const {slug} = this.state;
        const {jwtToken} = this.props.appState.state;
        const {logout} = this.props.appState.actions;
        if(slug){
            const result = await get(`/content/${slug}`, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));

            const pageContent = result.result;
            if(pageContent){
                this.setState({
                    loading: false,
                    title: pageContent.title,
                    content: <div>
                        <div dangerouslySetInnerHTML={{__html: pageContent.content}}></div>
                        <div className="author">Door {pageContent.lasteditby} op {moment(pageContent.lastedited).format('DD/MM/YYYY')}</div>
                    </div>
                })
            } else {
                this.setState({
                    loading: false,
                    title: 'Page not found',
                    content: `Page with slug ${slug} was not found`
                })
            }
        }else{
            const result = await get('/content', jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
            const pageContent = result.result;
            if(pageContent){
                this.setState({
                    loading: false,
                    title: `Informatie pagina's`,
                    content: <ul>
                        {pageContent.map((page, index) =>
                            <li key={index}>
                                <Link to={`/content/${page.slug}`}>{page.title}</Link>
                            </li>
                        )}
                    </ul>
                })
            }
        }
    }

    render() {
        const {slug, title, content} = this.state;
        return (
            <div className="Content">
                <Card title={title} bodyClass="fancy-body" headerClass="fancy-header">
                    {content}
                </Card>
                {
                    slug ? <Button inline className="card-positioning" onClick={this.props.history.goBack}>Terug</Button> : ''
                }
            </div>
        )
    }
}

export default withApplicationState(withRouter(Content));
