import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import "../assets/login.scss";
import {Button, Card} from "antd-mobile";
import {faUserLock, faSignInAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import queryString from 'query-string';
import {withApplicationState} from "react-application-state";
import applicationConfig from "../../../config/application";
//import {withApplicationState} from "../../../ras";

class Login extends Component {

    state = {
        redirect: false
    };


    componentDidMount = () => {
        const params = queryString.parse(this.props.location.search);
        if(params && params.token){
            this.props.appState.actions.setJwtToken(params.token);
            window.history.pushState({}, document.title, '/');
            this.props.history.push('/');
        }
    }

    login = () => {
        window.location = `${applicationConfig.JWT.serverUri}?returnUrl=${window.location}`;
    }

    render() {
        const {login} = this;
        return (
            <div className="Login">
                <div className="card-container">
                    <Card>
                        <Card.Header
                            title="Inloggen"
                            thumb={
                                <FontAwesomeIcon icon={faUserLock}/>
                            }
                        />
                        <Card.Body>
                            <div>
                                <img src="https://c.s-microsoft.com/en-us/CMSImages/Microsoft-logo_rgb_gray.jpg?version=1BC84E2E-14C4-6323-7909-D43A63EDAA93" alt="microsoft logo"/>
                                <p>Om gebruik te maken van deze applicatie, moet je inloggen met je Opgewekt Microsoft account</p>
                                <Button icon={
                                    <FontAwesomeIcon icon={faSignInAlt}/>
                                } type="primary" onClick={login}>
                                    Inloggen
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        )
    }
}

export default withApplicationState(withRouter(Login));
