//const setUserName = (name) => () => { return {name: name} };
/*
const setUserName = (name) => {
    return new Promise(function (resolve, reject) { resolve(() => {return {name: name};}) });
}
const setJwtToken = (token) => {
    return new Promise(function (resolve, reject) { resolve(() => {return {jwtToken: token};}) });
}
*/
const setUserName = (name) => () => {
    console.log('setUserName', name);
    return {name: name}
};
const setJwtToken = (token) => () => {
    console.log('setJwtToken', token);

    return {jwtToken: token}
};
const setUserId = (userId) => () => {
    console.log('setUserId', userId);

    return {userId: userId}
};
const setUserInfo = (name, id) => () => {
    console.log('setUserInfo', name, id);
    return {name: name, userId: id}
};
const logout = (message = '') => () => {
    console.log('logout', message);
    return {jwtToken: '', statusMessage: message}
};
const setMessage = (message = '') => () => {
    console.log('setMessage', message);
    return {statusMessage: message}
};

module.exports = {
    setUserName,
    setJwtToken,
    setUserId,
    setUserInfo,
    logout,
    setMessage
}
