import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import "../assets/empty.scss";
import Card from "../../card/components/card";

class Empty extends Component {
    render() {
        return (
            <div className="Empty">
                <Card title="Some card" bodyClass="fancy-body" headerClass="fancy-header">
                    <p>Text</p>
                </Card>
            </div>
        )
    }
}

export default withRouter(Empty);
