import {Component} from "react";
import AuthenticatorContext from "./authenticatorcontext";

export class IsNotAuthenticated extends Component {

    render() {
        return (
            !this.props.authState.isAuthenticated ?
                this.props.children : ''
        );
    }
}

export const WhenNotAuthenticated = AuthenticatorContext.withContext(IsNotAuthenticated)
