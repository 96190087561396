import React, { Component, Fragment } from 'react';
import "../assets/statusmessage.scss";
import {withApplicationState} from "react-application-state";
import {Button} from "antd-mobile";

class StatusMessage extends Component {
    render() {
        const {statusMessage} = this.props.appState.state;
        const {setMessage} = this.props.appState.actions;
        return (
            <Fragment>
                {
                    statusMessage ?
                        <div className="StatusMessage">
                            {statusMessage}
                            <Button inline type="primary" size="small" onClick={() => {
                                setMessage('')
                            }} className="dismiss-button">Ok</Button>
                        </div> :
                        <div></div>

                }
            </Fragment>
        )
    }
}

export default withApplicationState(StatusMessage);
