import React, { Component } from 'react';
import '../assets/app.scss';
import Layout from "./layout";
import StatusMessage from "../../statusmessage";
import Router from "../../router";
import "antd-mobile/dist/antd-mobile.css";
import {BrowserRouter} from "react-router-dom";
import {StateProvider} from "react-application-state";
//import {StateProvider} from "../../../ras";
import {Authenticator, WhenAuthenticated, WhenNotAuthenticated} from "../../authenticator";
import Login from "../../login";
//import Authenticator from "../../authenticator/components/authenticator";
const {actions} = require('./../../../actions');

export default class App extends Component {
  render() {
    return (
        <StateProvider actions={actions} initial={{name: 'no name', jwtToken: '', userId: '', statusMessage: ''}} options={{syncLocalStorage: true}}>
            <div className="App">
                <StatusMessage />
                <BrowserRouter>
                    <Authenticator>
                        <WhenAuthenticated>
                            <Layout>
                                <Router/>
                            </Layout>
                        </WhenAuthenticated>
                        <WhenNotAuthenticated>
                            <Login />
                        </WhenNotAuthenticated>
                    </Authenticator>
                </BrowserRouter>
            </div>
        </StateProvider>
    );
  }
}

