import React, {Component, Fragment} from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import Dashboard from "../../dashboard";
import ProjectInfo from "../../projectinfo";
import Empty from "../../empty";
import Content from "../../content";
import CMS from "../../cms";
import CMSEditor from "../../cmseditor";
import EmailSignatures from "../../emailsignatures";

class Router extends Component {
    render() {
        return <Fragment>
            <Switch>
                <Route path="/" exact component={Dashboard} />
                <Route path="/projects" exact component={ProjectInfo} />
                <Route path="/empty" exact component={Empty} />
                <Route path="/content" exact component={Content} />
                <Route path="/content/:slug" component={Content} />
                <Route path="/cms" exact component={CMS} />
                <Route path="/cms/:id" exact component={CMSEditor} />
                <Route path="/signatures" exact component={EmailSignatures} />
            </Switch>
        </Fragment>;
    }
}

export default withRouter(Router);
