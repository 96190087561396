import React, { Component, Fragment } from 'react';
import {withRouter} from "react-router-dom";
import Table from 'rc-table';
import {Modal, Button, Tag, Flex} from 'antd-mobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun, faLightbulb, faFutbol, faSearch, faHome, faBolt } from '@fortawesome/free-solid-svg-icons'
import "../assets/projectinfo.scss";
import Card from "../../card";

class ProjectInfo extends Component {

    formatNumber = (number, decimals = 0, separate = 3, thousandSeparator = '.', decimalSeparator = ',') => {
        if(!number) number = 0;
        const re = '\\d(?=(\\d{' + (separate || 3) + '})+' + (decimals > 0 ? '\\D' : '$') + ')';
        const num = number.toFixed(Math.max(0, ~~decimals));

        return (decimalSeparator ? num.replace('.', decimalSeparator) : num).replace(new RegExp(re, 'g'), '$&' + (thousandSeparator || ','));
    };


    constructor() {
        super();
        this.state = {
            data : [],
            modalVisible: false,
            loadingModal: false,
            modalData: []
        }
    }

    componentWillMount = async() => {
        const request = await fetch("https://data.slimopgewekt.nl/kiosk/0/listall");
        console.log("req", request);
        const data = await request.json();
        console.log("data", request);
        data.sort(function(a, b){
            const aKey = parseInt(a.id,10);
            const bKey = parseInt(b.id,10);
            if(aKey < bKey) return -1;
            if(aKey > bKey) return 1;
            return 0;
        });
        this.setState({data: data});
    }

    columns = [{
        title: '#',
        dataIndex: 'id',
    }, {
        title: 'Locatie',
        dataIndex: 'name',
    }, {
        title: 'Plaats',
        dataIndex: 'city_name'
    }, {
        title: 'Bereik',
        dataIndex: 'reach'
    }, {
        title: 'Aantallen',
        dataIndex: 'ledlights',
        render: (text, row, index) => {
            const tagClasses = 'ant-tag ant-tag-green';
            return (
                <Fragment>
                    <Tag title="Zonnepanelen">
                        <div data-show="true" key={`${index}-led-2`} className={tagClasses}>
                            <FontAwesomeIcon icon={faSun} />
                            <span className="ant-tag-text">&nbsp;{row.panels}</span>
                        </div>
                    </Tag>
                    <Tag title="Binnen verlichting">
                        <div data-show="true" key={`${index}-led-1`} className={tagClasses}>
                            <FontAwesomeIcon icon={faLightbulb} />
                            <span className="ant-tag-text">&nbsp;{row.ledlights}</span>
                        </div>
                    </Tag>
                    <Tag title="Veld verlichting">
                        <div data-show="true" key={`${index}-led-2`} className={tagClasses}>
                            <FontAwesomeIcon icon={faFutbol} />
                            <span className="ant-tag-text">&nbsp;{row.ledlights_outside}</span>
                        </div>
                    </Tag>
                </Fragment>
            )
        }
    }, {
        title: 'Besparing/Jaar',
        dataIndex: 'year_pv_savings_exp',
        render: (text, row, index) => {
            const {formatNumber} = this;
            const yearLed = parseInt(row.year_led_savings,10);
            const yearPV = parseInt(row.year_pv_savings_exp,10);
            return formatNumber((!isNaN(yearLed) ? yearLed : 0) + (!isNaN(yearPV) ? yearPV : 0),0,3,'.') + ' kWh';
        }
    }, {
        title: 'Metaforen',
        dataIndex: 'year_now',
        render: (text, row, index) => {
            return (
                <Button onClick={() => this.showDetails(row.id)} size="small">
                    Bekijk <FontAwesomeIcon icon={faSearch} />
                </Button>
            )
        }
    }];

    genModalData = (dataSet) => {
        const {formatNumber} = this;
        const expected_year = (dataSet.year_pv_savings_exp + dataSet.year_led_savings);
        const actual_total = (dataSet.led_total + dataSet.energy_total);
        return [
            {key: 'pv', text: 'Zonnepanelen', value: dataSet.panels},
            {key: 'led', text: 'Ledlampen', value: dataSet.ledlights},
            {key: 'ledv', text: 'Veldverlichting', value: dataSet.ledlights_outside},
            {key: 'pv_exp', text: 'Jaarlijkse besparing (PV)', value: formatNumber(dataSet.year_pv_savings_exp, 0,3,'.') + ' kWh/jaar'},
            {key: 'led_exp', text: 'Jaarlijkse besparing (LED)', value: formatNumber(dataSet.year_led_savings,0,3,'.') + ' kWh/jaar'},
            {key: 'co2', text: 'CO2 besparing', value: formatNumber(expected_year * 0.56,0,3,'.') + ' kg/jaar'},
            {key: 'meta_households', text: '# Huishoudens', value: formatNumber(expected_year * (1/3500),0,3,'.')},
            {key: 'meta_trees', text: '# Bomen', value: formatNumber(expected_year * 0.028,0,3,'.')},
            {key: 'meta_soccer', text: 'Voetbalvelden met bomen', value: formatNumber(expected_year * 0.028 * 0.0035,1,3,'.')},
            {key: 'meta_hockey', text: 'Hockeyvelden met bomen', value: formatNumber(expected_year * 0.028 * 0.00497,1,3,'.')},
            {key: 'meta_timboektoe', text: 'Ritjes naar Timboektoe', value: (expected_year * (1/4000),0,3,'.') + ' keer'},
            {key: 'meta_sidney', text: 'Ritjes naar Sydney', value: formatNumber(expected_year * (1/16600),0,3,'.') + ' keer'},
            {key: 'meta_earth', text: 'Rondjes om de aarde', value: formatNumber(expected_year * (1/40000),0,3,'.') + ' keer'},
            {key: 'meta_car', text: 'Afstand elektrische auto', value: formatNumber(expected_year * (1/6.67),0,3,'.') + ' km'},
            {key: 'meta_mower', text: 'Elektrische grasmaaier', value: formatNumber(expected_year * (1/1.2),0,3,'.') + ' uur'},
            {key: 'meta_scooter', text: 'Elektrische scooter', value: formatNumber(expected_year * (1/2.31),0,3,'.') + ' km'},
            {key: 'meta_teamsoccer', text: 'voetballen met team', value: formatNumber(expected_year * (1/6.71),0,3,'.') + ' uur'},
            {key: 'meta_electrocar', text: 'Electrocar', value: formatNumber(expected_year * 0.13,0,3,'.') + ' km'},
            {key: 'total_exp', text: 'Totale besparing (Verwacht)', value: formatNumber(dataSet.led_total + dataSet.pv_total_exp,0,3,'.') + ' kWh'},
            {key: 'total_real', text: 'Totale besparing (Werkelijk)', value: formatNumber(actual_total,0,3,'.') + ' kWh'},

            // CO2 besparing (kg per jaar)
            // # huishoudens
            // # bomen
            // past op voetbalvelden "
            // past op hockeyvelden
            // timboektoe
            // sidney
            // om de aarde
            // elektrische auto
            // elekt grasmaaier
            // elekt scooter
            // hoelang voetballen met je team
            // electrocar
        ];
    }

    showDetails = async (id) => {
        await this.setState({modalVisible: true, loadingModal: true});
        console.log("click", id);
        const request = await fetch("https://data.slimopgewekt.nl/kiosk/" + id.toString() + "/projectdetails");
        const data = await request.json();
        this.setState({loadingModal: false, modalData: this.genModalData(data)});
        console.log(data);
    }

    renderModalTable = (data) => {

        const columns = [{
            title: 'Onderwerp',
            dataIndex: 'text',
            key: 'text',
        }, {
            title: 'Waarde',
            dataIndex: 'value',
            key: 'value',
        }];

        return <Table data={data} columns={columns} pagination={false} />
    }

    render = () => {
        const {formatNumber} = this;
        const {data} = this.state;
        return (
            <div className="ProjectInfo">
                <Card title="Totalen" className="totals">
                    <Flex direction="row" wrap="wrap">
                        <div className="card-box">
                            <span className="btn-icon btn-icon-round btn-icon-lg-alt bg-color-success">
                                <FontAwesomeIcon icon={faHome} />
                            </span>
                            <div className="box-info">
                                <p className="box-num">{data.length}</p>
                                <p className="text-muted">Locaties</p>
                            </div>
                        </div>
                        <div className="card-box">
                            <span className="btn-icon btn-icon-round btn-icon-lg-alt bg-color-info">
                                <FontAwesomeIcon icon={faSun} />
                            </span>
                            <div className="box-info">
                                <p className="box-num">{formatNumber(data.reduce((total, loc) => {
                                    return total + parseInt(loc.panels,10);
                                }, 0),0, 3, '.')}</p>
                                <p className="text-muted">Zonnepanelen</p>
                            </div>
                        </div>
                        <div className="card-box">
                            <span className="btn-icon btn-icon-round btn-icon-lg-alt bg-color-warning">
                                <FontAwesomeIcon icon={faLightbulb} />
                            </span>
                            <div className="box-info">
                                <p className="box-num">{formatNumber(data.reduce((total, loc) => {
                                    return total + parseInt(loc.ledlights,10) + parseInt(loc.ledlights_outside,10);
                                }, 0),0, 3, '.')}</p>
                                <p className="text-muted">Lampen</p>
                            </div>
                        </div>
                        <div className="card-box">
                            <span className="btn-icon btn-icon-round btn-icon-lg-alt bg-color-primary">
                                <FontAwesomeIcon icon={faBolt} />
                            </span>
                            <div className="box-info">
                                <p className="box-num">{formatNumber(data.reduce((total, loc) => {
                                    const yearLed = parseInt(loc.year_led_savings,10);
                                    const yearPV = parseInt(loc.year_pv_savings_exp,10);
                                    return total +
                                        (!isNaN(yearLed) ? yearLed : 0) +
                                        (!isNaN(yearPV) ? yearPV : 0);
                                }, 0),0, 3, '.')} <span className="size-h4">kWh</span></p>
                                <p className="text-muted">Besparing/jaar</p>
                            </div>
                        </div>
                    </Flex>
                </Card>
                <Card title="Locaties" className="table" bodyClass="table-card-body" headerClass="table-card-header">
                    <Table
                        className="project-table"
                        columns={this.columns}
                        data={data}
                        pagination={false}
                        bordered={false}
                        rowKey={'id'}
                        style={{marginBottom: '30px'}}
                    />
                </Card>
                <Modal
                    title="Metaforen"
                    visible={this.state.modalVisible}
                    onCancel={() => this.setState({ modalVisible : false})}
                    footer={[
                        {
                            text: 'Ok',
                            onPress: () => this.setState({modalVisible: false})
                        }
                    ]}
                >
                    {this.renderModalTable(this.state.modalData)}
                </Modal>
            </div>
        );
    }
}

export default withRouter(ProjectInfo);
