import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import {withApplicationState} from "react-application-state";
import "../assets/emailsignatures.scss";
import {Button, InputItem, Toast} from "antd-mobile";
import Card from "../../card";
import {get, post} from "../../../lib/api";
import applicationConfig from "../../../config/application";
import { ImagePicker } from 'antd-mobile';
import {config} from "@fortawesome/fontawesome-svg-core";


class EmailSignatures extends Component {

    constructor(props){
        super(props);
        this.state = {
            loading: false,
            showImagePicker: false,
            name: props.appState.state.name,
            title: '',
            phone: '',
            email: '',
            picture: '',
            signatures: [],
            pictures: [],
            imageAntiCache: ''
        }
    }

    onChangePicture = (index, files) => {
        this.setState({
            picture: files[index].url,
            showImagePicker: false
        });
    };

    componentDidMount = async () => {
        const {userId, jwtToken} = this.props.appState.state;
        const {logout} = this.props.appState.actions;
        const info = await get(`/signatures/${userId}/information`, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
        if(!info || info.error)
            return;
        const signatures = await get(`/signatures/${userId}`, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
        if(!signatures || signatures.error)
            return;
        const pictures = await get(`/signatures/photos`, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
        if(!pictures || pictures.error)
            return;
        this.setState({
            loading: false,
            signatures: signatures.result.map(sig => {
                return {brand: sig.brand, path: sig.file}
            }),
            pictures: [{url: '', index: -1}, ...pictures.result.map((picture, index) => {
                return {url: picture, id: index};
            })], ...info.result});
    }

    save = async () => {
        const {name, title, email, phone, picture} = this.state;
        const data = {name, title, email, phone, picture};
        const {userId, jwtToken} = this.props.appState.state;
        const {logout} = this.props.appState.actions;
        Toast.loading('verwerken', 30);
        this.setState({loading: true});
        const result = await post(`/signatures/${userId}/information`, data, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
        if(!result || result.error)
            return;
        this.setState({
            loading: false,
            signatures: result.signatures,
            imageAntiCache: Date.now()
        });
        Toast.hide();
        window.location.reload()

    }

    showInstructions = () => {
        this.props.history.push('/content/emailhandtekening')
    }

    resyncImages = async () => {
        const {jwtToken} = this.props.appState.state;
        const {logout, setMessage} = this.props.appState.actions;
        Toast.loading('hersynchroniseren', 30);
        const pictures = await get(`/signatures/sync-photos`, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
        if(!pictures || pictures.error)
            return;
        this.setState({
            loading: false,
            pictures: [{url: '', index: -1}, ...pictures.result.map((picture, index) => {
                return {url: picture, id: index};
            })]
        });
        Toast.hide();
        setMessage(`Foto's gesynchroniseerd met dropbox. Het kan tot een uur duren voor nieuwe foto's beschikbaar zijn.`);
    }

    render() {
        const {showImagePicker, name, title, email, phone, signatures, picture, pictures, imageAntiCache} = this.state;
        return (
            <div className="EmailSignatures">
                <Card title="Gegevens" bodyClass="fancy-body" headerClass="fancy-header">
                    <InputItem
                        value={name}
                        onChange={(val) => this.setState({name: val})}
                    >Naam</InputItem>
                    <InputItem
                        value={title}
                        onChange={(val) => this.setState({title: val})}
                    >Rol</InputItem>
                    <InputItem
                        value={phone}
                        onChange={(val) => this.setState({phone: val})}
                    >Telefoon</InputItem>
                    <InputItem
                        value={email}
                        onChange={(val) => this.setState({email: val})}
                    >E-mail</InputItem>
                        <div className="am-list-item am-input-item am-list-item-middle picture-row">
                        <div className="am-list-line">
                            <div className="am-input-label am-input-label-5">Foto</div>
                            <div className="am-input-control">
                                {picture ? <img src={picture} alt="gekozen foto" className="selected-picture" /> : 'Geen foto gekozen'}
                                {
                                    !showImagePicker ?
                                        <Button inline size="small" className="select-picture-btn"
                                                onClick={() => this.setState({showImagePicker: true})}>foto kiezen</Button> : ''
                                }
                            </div>
                        </div>
                    </div>
                    {
                        showImagePicker ?
                            <div>
                                <ImagePicker
                                    length={8}
                                    files={pictures}
                                    onImageClick={this.onChangePicture}
                                    selectable={true}
                                    multiple={false}
                                    onAddImageClick={null}
                                />
                                <Button inline size="small" onClick={this.resyncImages}>Foto's opnieuw synchroniseren met Dropbox</Button>
                            </div>: ''
                    }
                </Card>
                <Button inline className="card-positioning" onClick={this.save}>Handtekeningen genereren</Button>
                <Button inline className="card-positioning" onClick={this.showInstructions}>Instructies voor gebruik</Button>
                <Card title="Handtekeningen" bodyClass="fancy-body" headerClass="fancy-header">
                    {signatures.map(data =>
                        <div key={data.brand}>
                            <img src={`${data.path}${imageAntiCache ? `?_=${imageAntiCache}` : ''}`} alt={`${data.brand} signature`} />
                        </div>
                    )}
                </Card>
            </div>
        )
    }
}

export default withApplicationState(withRouter(EmailSignatures));
