import React from "react";

const Context = React.createContext();

Context.Provider.displayName = "Authenticator.Provider";
Context.Consumer.displayName = "Authenticator.Consumer";
Context.withContext =  Component => props => {
    return (
        <Context.Consumer>
            {state => {
                return (
                    <Component
                        authState={state}
                        {...props}
                    />
                );
            }}
        </Context.Consumer>
    );
};

export default Context;
