export default {
    JWT: {
        allowedAudience: 'so-tools',
        allowedIssuer: 'so-tools',
        serverUri: 'https://tools-api.slimopgewekt.app/auth',
        tokenExpiredMessage: 'Je sessie is verlopen! Helaas betekend dat dat je opnieuw moet inloggen.'
    },
    theme: {
        colors: {
            primary: '#ffeb70',
            secondary: '#7bebcf',
            tertiary: '#252166'
        }
    },
    api: {
        uri: 'https://tools-api.slimopgewekt.app'
    }
}
