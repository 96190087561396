import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import "../assets/dashboard.scss";
import ToolButton from "./toolbutton";
import {faChartPie, faImage, faInfo} from "@fortawesome/free-solid-svg-icons";

class Dashboard extends Component {
    render() {
        return (
            <div className="Dashboard">
                <div className="tool-buttons" >
                    <ToolButton linkTo="/signatures" text="Email handtekeningen" icon={faImage} />
                    <ToolButton linkTo="/content" text="Informatie pagina's" icon={faInfo} />
                    <ToolButton linkTo="/projects" text="Projecten overzicht" icon={faChartPie} />
                </div>
            </div>
        );
    }
}

export default withRouter(Dashboard);

