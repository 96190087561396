import React, { Component } from 'react';
import {Link, withRouter} from "react-router-dom";
import "../assets/cms.scss";
import Card from "../../card";
import 'draft-js/dist/Draft.css';
import {Button} from "antd-mobile";
import {Toast} from "antd-mobile";
import {get} from "../../../lib/api";
import {withApplicationState} from 'react-application-state';
import applicationConfig from "../../../config/application";

class CMS extends Component {

    constructor(props){
        super(props);
        this.state = {
            loading: true,
            contents: []
        }
    }


    componentDidMount = async () => {
        const {userId, jwtToken} = this.props.appState.state;
        const {logout} = this.props.appState.actions;
        if(userId !== '0550b99f-60b0-4406-9272-7eaf53e951f8')
            this.props.history.push('/content');

        Toast.loading('Loading...', 30);

        const result = await get('/content', jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
        const contents = result.result;
        if(contents){
            this.setState({
                loading: false,
                contents: contents
            });
            Toast.hide();
        }
    }

    render() {
        const {loading, contents} = this.state;
        return !loading ? (
            <div className="CMS">
                <Card title="Bewerkbare pagina's" bodyClass="fancy-body" headerClass="fancy-header">
                    <ul>
                        {contents.map(page =>
                            <li>
                                <Link to={`/cms/${page.id}`}>{page.title}</Link>
                            </li>
                        )}
                    </ul>
                </Card>
                <Button inline className="card-positioning"  onClick={() => this.props.history.push('/cms/0')}>Nieuwe pagina toevoegen</Button>
            </div>
        ) : '';
    }
}

export default withApplicationState(withRouter(CMS));
