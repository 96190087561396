import React, { Component } from 'react';
import {Flex, Menu, NavBar} from "antd-mobile";
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import {faBars, faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {withApplicationState} from "react-application-state";
import logoSVG from '../../../shared-assets/logo/so.svg';
import SVG from 'react-inlinesvg';

class Layout extends Component {
    static propTypes = {
        children: PropTypes.object,
        location: PropTypes.shape({
            pathname: PropTypes.string
        }),
        history: PropTypes.object,
        appState: PropTypes.object
    };
    state = {
        showMenu: false,
        showUserMenu: false
    };
    menuData = [
        {
            label: 'Home',
            value: '/'
        },
        {
            label: 'Email handtekeningen',
            value: '/signatures'
        },
        {
            label: 'Informatie pagina\'s',
            value: '/content'
        },
        {
            label: 'Projectenoverzicht',
            value: '/projects'
        },
    ];
    userMenuData = [
        {
            label: 'Uitloggen',
            value: 'logout'
        },
    ];

    onMenuChange = (selected) => {
        if(selected.length === 1){
            this.props.history.push(selected[0]);
            this.setState({
                showMenu: false,
            });
        }
    };

    onMenuClick = (e) => {
        e.preventDefault(); // Fix event propagation on Android
        this.setState({
            showMenu: !this.state.showMenu,
        });
    };

    onUserMenuChange = (selected) => {
        if(selected && selected.length === 1){
            if(selected[0] === 'logout'){
                this.setState({
                    showUserMenu: false,
                });
                this.props.appState.actions.setJwtToken('');
            }
        }
    };

    onUserMenuClick = (e) => {
        e.preventDefault(); // Fix event propagation on Android
        this.setState({
            showUserMenu: !this.state.showUserMenu,
        });
    };

    onMaskClick = () => {
        this.setState({
            showMenu: false,
            showUserMenu: false
        });
    };
    render() {
        const { showMenu, showUserMenu } = this.state;

        return (
            <Flex direction="column" wrap="wrap" justify="start" align="stretch" className="Layout" >
                <Flex.Item className={"header"}>
                    <header>
                        <div className={showMenu ? 'single-menu-active' : ''}>
                            <div>
                                <NavBar
                                    leftContent={<FontAwesomeIcon icon={faBars} size="lg" />}
                                    rightContent={<span onClick={this.onUserMenuClick}>
                                        {this.props.appState.state.name}
                                        <FontAwesomeIcon icon={faUser} size="lg" />
                                    </span>}
                                    mode="light"
                                    onLeftClick={this.onMenuClick}
                                    className="single-top-nav-bar"
                                >
                                    <div className="logo-wrapper">
                                        <SVG src={logoSVG} className="logo" />
                                    </div>
                                </NavBar>
                            </div>
                            {showMenu ?
                                <Menu
                                    className="single-foo-menu nav-menu"
                                    data={this.menuData}
                                    level={1}
                                    value={[this.props.location.pathname]}
                                    onChange={this.onMenuChange}
                                    height={this.menuData * 44}
                                />: null}
                            {showUserMenu ?
                                <Menu
                                    className="single-foo-menu user-menu"
                                    data={this.userMenuData}
                                    level={1}
                                    value={[]}
                                    onChange={this.onUserMenuChange}
                                    height={this.userMenuData * 44}
                                />: null}
                            {showMenu || showUserMenu ? <div className="menu-mask" onClick={this.onMaskClick} /> : null}
                        </div>
                    </header>
                </Flex.Item>
                <Flex.Item className={"body"}>
                    <div className="content-container">
                        {this.props.children}
                    </div>
                </Flex.Item>
            </Flex>
        );
    }
}
export default withApplicationState(withRouter(Layout));
