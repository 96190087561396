import React, { Component } from 'react';
import {Link, withRouter} from "react-router-dom";
import {Flex} from "antd-mobile";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class ToolButton extends Component {
    static propTypes = {
        text: PropTypes.string,
        icon: PropTypes.object,
        linkTo: PropTypes.string
    };

    render() {
        return (
            <div className="ToolButton">
                <Link to={this.props.linkTo} className="link">
                    <Flex align="center" justify="center" className="tool-content-container">
                        <Flex.Item className="tool-content content-icon">
                            <FontAwesomeIcon icon={this.props.icon} size="4x" />
                        </Flex.Item>
                        <Flex.Item className="tool-content content-text">
                            {this.props.text}
                        </Flex.Item>
                    </Flex>
                </Link>
            </div>
        );
    }
}

export default withRouter(ToolButton);
