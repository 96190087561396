import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import "../assets/cmseditor.scss";
import Card from "../../card";
import ContentEditor from "../../contenteditor/components/contenteditor";
import {withApplicationState} from "react-application-state";
import {Button, InputItem, Toast} from "antd-mobile";
import {get, post} from "../../../lib/api";
import applicationConfig from "../../../config/application";

class CMSEditor extends Component {

    constructor(props){
        super(props);
        this.state = {
            loading: true,
            id: props.match.params.id,
            title: '',
            content: '',
            slug: '',
        }
    }


    componentDidMount = async () => {
        const {userId, jwtToken} = this.props.appState.state;
        const {logout} = this.props.appState.actions;
        if(userId !== '0550b99f-60b0-4406-9272-7eaf53e951f8')
            this.props.history.push('/content');

        Toast.loading('Loading...', 30);
        if(this.state.id > 0){
            const result = await get(`/content/id/${this.state.id}`, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
            const pageContent = result.result;
            if(pageContent){
                this.setState({
                    loading: false,
                    title: pageContent.title,
                    slug: pageContent.slug,
                    content: pageContent.content
                });
            }
            Toast.hide();
        }else{
            this.setState({
                loading: false,
            });
            setTimeout(() => Toast.hide(), 500)
        }
    }

    save = async() => {
        const {jwtToken} = this.props.appState.state;
        const {logout} = this.props.appState.actions;
        const {id, title, slug, content} = this.state;
        this.setState({loading: true});
        const result = await post(`/content/id/${id}`, {
            title,
            slug,
            content
        }, jwtToken, () => logout(applicationConfig.JWT.tokenExpiredMessage));
        if(id > 0)
            this.setState({loading: false});
        else
            this.setState({loading: false, id: result.id})

    }

    render() {
        const {loading, title, content, slug} = this.state;
        return !loading ? (
            <div className="CMSEditor">
                <Button inline className="card-positioning" onClick={this.save}>Opslaan</Button>
                <Card title="Gegevens" bodyClass="fancy-body" headerClass="fancy-header">
                    <InputItem
                        value={title}
                        onChange={(val) => this.setState({title: val})}
                    >Titel</InputItem>
                    <InputItem
                        value={slug}
                        onChange={(val) => this.setState({slug: val})}
                    >Slug</InputItem>
                </Card>
                <Card title="Content bewerken" bodyClass="fancy-body" headerClass="fancy-header">
                    <ContentEditor onChange={(val) => this.setState({content: val})} initialContent={content}/>
                </Card>
                <Button inline className="card-positioning" onClick={this.save}>Opslaan</Button>
            </div>
        ) : '';
    }
}

export default withApplicationState(withRouter(CMSEditor));
