import { Component } from 'react';
import AuthenticatorContext from './authenticatorcontext'

export class IsAuthenticated extends Component {

    render() {
        return (
            this.props.authState.isAuthenticated ?
                this.props.children : ''
        );
    }
}

export const WhenAuthenticated = AuthenticatorContext.withContext(IsAuthenticated)
